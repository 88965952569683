<template>
  <collapse-card
    v-bind="$attrs"
    :model-value="true"
  >
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Comments
      </span>
    </template>
    <template #cardContent>
      <div class="py-2 px-4">
        <div class="grid grid-cols-2 gap-x-10 gap-y-4 mb-6">
          <base-select
            v-if="customCommentsDropdownState !== INVISIBLE"
            class="flex items-center text-sm text-left"
            label="Custom Comments:"
            label-class="label-w-46"
            :options="getDistinctArray(customLinesOptions)"
            disable-type-ahead
            :ignore-selection="true"
            @dropdownOpened="fetchCustomLines()"
            @update:model-value="updateCustomLineValueInComments($event)"
          />
        </div>
        <div class="grid grid-cols-2 gap-x-10 gap-y-4">
          <!-- licensee section starts -->
          <div class="flex flex-col">
            <div class="flex items-center mb-6">
              <div class="mr-5 flex-shrink-0">
                <div
                  class="text-white bg-primary-red py-2 px-2 cursor-pointer rounded-sm"
                  @click="openLicenseeCommentsHistory()"
                >
                  Licensee Comments History
                </div>
              </div>
              <div>
                Please click on <strong>licensee comment history</strong> button for a full list of licensee comments.
              </div>
            </div>
            <base-input
              :model-value="previousLicenseeComment"
              type="text"
              container-class="w-full mb-10"
              placeholder="--No Comments are available--"
              disabled
              label="Previous Licensee Comment"
            />
            <base-input
              v-if="licenseeCommentFieldState() !== INVISIBLE"
              v-model="submissionForm.licenseeComment"
              text-area
              label="Licensee Comment"
              rows="5"
              container-class="w-full mb-4"
              :error="submissionFormValidations.licenseeComment || submissionForm.licenseeComment.length > 4000"
              :is-required="licenseeCommentFieldState() === REQUIRED || submissionForm.licenseeComment.length > 4000 ? true : false"
              :error-text="submissionForm.licenseeComment.length > 4000 ? 'Only 4000 Characters are allowed.' : errorTextLicenseeComments"
            />
          </div>
          <!-- licensee section ends -->
          <!-- internal notes section starts -->
          <div
            v-if="internalNotesFullAreadFieldState !== INVISIBLE"
            class="flex flex-col"
          >
            <div
              v-if="internalNotesHistoryButtonState !== INVISIBLE"
              class="flex items-center mb-6"
            >
              <div class="mr-5 flex-shrink-0">
                <div
                  class="text-white bg-primary-red py-2 px-2 cursor-pointer rounded-sm"
                  @click="openInternalNotesHistory()"
                >
                  Internal Notes History
                </div>
              </div>
              <div>
                Please click on <strong>internal notes history</strong> button for a full list of internal comments.
              </div>
            </div>
            <base-input
              v-if="previousInternalNoteFieldState !== INVISIBLE"
              v-model="previousInternalNote"
              type="text"
              label="Previous Internal Note"
              container-class="w-full mb-10"
              placeholder="--No Comments are available--"
              disabled
            />
            <base-input
              v-if="internalNoteFieldState !== INVISIBLE"
              v-model="submissionForm.internalNote"
              text-area
              container-class="w-full mb-4"
              rows="5"
              label="Internal Notes"
              :error="submissionFormValidations.internalNote || submissionForm.internalNote.length > 4000"
              :is-required="internalNoteFieldState === REQUIRED || submissionForm.internalNote.length > 4000 ? true : false"
              :error-text="submissionForm.internalNote.length > 4000 ? 'Only 4000 Characters are allowed.' : errorTextLicenseeComments"
            />
          </div>
          <!-- internal notes section ends -->
        </div>
      </div>
    </template>
  </collapse-card>
  <!-- comments modal -->
  <brand-assurance-comments-modal
    v-model="showCommentsModal"
    :modal-title="commentsModalTitle"
    @hide="setCommentsModalVisibility(false)"
  />
</template>

<script>
import { computed, defineAsyncComponent, reactive, ref, watch, onMounted } from 'vue';
import useSubmissionForm from './submissionForm.js';
import useComments from '@/components/brand-assurance-comments/comments.js';
import { useStore } from 'vuex';
import { getDistinctArray } from '@/helpers/util';
import {
    ACTIVE_BUTTON, APPROVE_STATUS, APPROVE_WITH_CHANGES_STATUS, ASSIGN_TO_STATUS, EDITABLE, INVISIBLE,
    CREATIVE_REVIEW_STEP, LEGAL_REVIEW_STEP, LICENSEE_STEP, PRESS_RELEASE_SUBMISSION_TYPE, REQUIRED, RESUBMIT_WITH_CHANGES_STATUS,
    ROUTE_BACK_STATUS, VIEW_ONLY, BAC_SUPERVISOR_STEP, BAC_REVIEW_STEP, REJECT_STATUS, BAC_FINAL_STEP, SAFETY_STEP,
    CONTRACT_COORDINATOR, SOCIAL_REVIEW_STEP, SOCIAL_DIGITAL_VIDEO_REVIEW, GAMES_REVIEW_STEP, DIGITAL_REVIEW_STEP
} from '@/constants/submissions.js';
import { REQUIRED_FIELD } from '@/constants/validationMessages.js';
import useUserAuth from '@/hooks/userAuth.js';
import { SUBMISSION_FORM_CUSTOM_COMMENTS_PERMISSION, SUBMISSION_FORM_INTERNAL_COMMENTS_PERMISSION } from '@/constants/permissions.js';
import { BA_ADMIN_ROLE, LICENSEE_ROLE, BA_RO_ROLE} from '@/constants/ba-roles.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { ERROR, WARNING } from '@/constants/alerts';
import * as BA_ROLES from '@/constants/ba-roles.js';

export default {
    name: 'Comments',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        BrandAssuranceCommentsModal: defineAsyncComponent(() => import('@/components/brand-assurance-comments/BrandAssuranceCommentsModal.vue'))
    },

    inheritAttrs: false,

    setup () {
        const store = useStore();
        const { notificationsStack } = useToastNotifications();
        const { submissionForm, notifiersEnabled, submissionFormMode, submissionFormValidations } = useSubmissionForm();
        const { setCommentsLists, isCommentsListLoading } = useComments();
        const { userPermissions, userRoles } = useUserAuth();
        const proxyUser = computed(() => store.getters['auth/getUserProxyInfo']);

        const userId = computed(() => store.getters['auth/getUserId']);

        // comments validations logic
        const customCommentsDropdownState = computed(() => {
            if (userPermissions.value.includes(SUBMISSION_FORM_CUSTOM_COMMENTS_PERMISSION) &&
              [LEGAL_REVIEW_STEP, CREATIVE_REVIEW_STEP, BAC_REVIEW_STEP, BAC_SUPERVISOR_STEP].includes(submissionForm.currentStepName)) {
                return EDITABLE;
            } else if (submissionForm.isAdminEdit) {
                return EDITABLE;
            } else {
                return INVISIBLE;
            }
        });

        const errorTextInternalNotes = ref('');

        watch(
            () => submissionForm.escalateToSupervisor,
            () => {
                if (submissionForm.escalateToSupervisor === 'Y') {
                    if (submissionForm.internalNote === '') {
                        errorTextInternalNotes.value = 'Please enter the reason for Escalate to Supervisor in Internal Notes.';
                        submissionFormValidations.internalNote = REQUIRED_FIELD;
                    }
                } else {
                    submissionFormValidations.internalNote = '';
                }
            }, { deep: true, immediate: true }
        );

        const validationCheckForCommentFields = () => {
            submissionFormValidations.licenseeComment = (licenseeCommentFieldState() === REQUIRED && !submissionForm.licenseeComment) || submissionForm.licenseeComment.length > 4000 ? REQUIRED_FIELD : '';
            submissionFormValidations.internalNote = (internalNoteFieldState.value === REQUIRED && !submissionForm.internalNote) || submissionForm.internalNote.length > 4000 ? REQUIRED_FIELD : '';
        };

        // making seperate watchers on purpose.
        watch(
            () => [submissionForm.currentActionName],
            (newVals, oldVals) => {
                if (![LEGAL_REVIEW_STEP, CREATIVE_REVIEW_STEP].includes(submissionForm.currentStepName)) {
                    errorTextInternalNotes.value = '';
                    errorTextLicenseeComments.value = '';

                    const tmpIdx = newVals.findIndex((e, i) => e !== oldVals[i]);
                    if (tmpIdx < 0) return; // nothing changed

                    dynamicLicenseeCommentsTextPopulation(newVals[tmpIdx]);
                    dynamicInternalNotesTextPopulation(newVals[tmpIdx]);

                    validationCheckForCommentFields();
                }
            }
        );

        watch(
            () => [submissionForm.legalStatusName],
            (newVals, oldVals) => {
                if (submissionForm.currentStepName === LEGAL_REVIEW_STEP) {
                    errorTextInternalNotes.value = '';
                    errorTextLicenseeComments.value = '';

                    const tmpIdx = newVals.findIndex((e, i) => e !== oldVals[i]);
                    if (tmpIdx < 0) return; // nothing changed

                    dynamicLicenseeCommentsTextPopulation(newVals[tmpIdx]);
                    dynamicInternalNotesTextPopulation(newVals[tmpIdx]);

                    validationCheckForCommentFields();
                }
            }
        );

        watch(
            () => [submissionForm.creativeStatusName],
            (newVals, oldVals) => {
                if (submissionForm.currentStepName === CREATIVE_REVIEW_STEP) {
                    errorTextInternalNotes.value = '';
                    errorTextLicenseeComments.value = '';

                    const tmpIdx = newVals.findIndex((e, i) => e !== oldVals[i]);
                    if (tmpIdx < 0) return; // nothing changed

                    dynamicLicenseeCommentsTextPopulation(newVals[tmpIdx]);
                    dynamicInternalNotesTextPopulation(newVals[tmpIdx]);

                    validationCheckForCommentFields();
                }
            }
        );

        // LICENSEE COMMENTS AREA
        const errorTextLicenseeComments = ref('');

        const dynamicLicenseeCommentsTextPopulation = (actionName) => {
            if (submissionForm.currentStepName === BAC_REVIEW_STEP) {
                if ([RESUBMIT_WITH_CHANGES_STATUS, REJECT_STATUS].includes(actionName)) {
                    if (submissionForm.submissionTypeName === PRESS_RELEASE_SUBMISSION_TYPE) {
                        errorTextLicenseeComments.value = actionName === RESUBMIT_WITH_CHANGES_STATUS ? 'Please make sure you have added File Name in Licensee Comments.' : `Please enter the reason for ${actionName} in Licensee Comments.`;
                        if (submissionForm.licenseeComment === '') {
                            if (notifiersEnabled.value) notificationsStack.value.push({
                                type: WARNING,
                                message: actionName === RESUBMIT_WITH_CHANGES_STATUS ? 'Please make sure you have added File Name in Licensee Comments.' : `Please enter the reason for ${actionName} in Licensee Comments.`
                            });
                        }
                    } else {
                        errorTextLicenseeComments.value = `Please enter the reason for ${actionName} in Licensee Comments.`;
                        if (submissionForm.licenseeComment === '') {
                            if (notifiersEnabled.value) notificationsStack.value.push({
                                type: WARNING,
                                message: 'Please make sure you have added Licensee Comments for this action.'
                            });
                        }
                    }
                }
            } else if (submissionForm.currentStepName === LEGAL_REVIEW_STEP) {
                if ([APPROVE_STATUS].includes(actionName)) {
                    submissionForm.licenseeComment = submissionForm.licenseeComment || 'APPROVED';
                    errorTextLicenseeComments.value = 'Please provide Comments to the Licensee.';
                } else {
                    if (submissionForm.licenseeComment) submissionForm.licenseeComment = submissionForm.licenseeComment.replaceAll('APPROVED', '');
                }
                if ([APPROVE_WITH_CHANGES_STATUS, RESUBMIT_WITH_CHANGES_STATUS, REJECT_STATUS].includes(actionName)) {
                    if (submissionForm.submissionTypeName === PRESS_RELEASE_SUBMISSION_TYPE) {
                        if (submissionForm.licenseeComment === '') {
                            errorTextLicenseeComments.value = actionName === REJECT_STATUS ? `Please enter the reason for ${actionName} in Licensee Comments.` : 'Please make sure you have added File Name in Licensee Comment.';
                            if (notifiersEnabled.value) notificationsStack.value.push({
                                type: WARNING,
                                message: actionName === REJECT_STATUS ? 'Please make sure you have added Licensee Comments for this action.' : 'Please make sure you have added File Name in Licensee Comment.'
                            });
                        }
                    } else {
                        errorTextLicenseeComments.value = `Please enter the reason for ${actionName} in Comments.`;
                        if (submissionForm.licenseeComment === '') {
                            if (notifiersEnabled.value) notificationsStack.value.push({
                                type: WARNING,
                                message: 'Please make sure you have added Licensee Comments for this action.'
                            });
                        }
                    }
                }
            } else if ([SAFETY_STEP].includes(submissionForm.currentStepName)) {
                if ([APPROVE_STATUS].includes(actionName)) {
                    submissionForm.licenseeComment = submissionForm.licenseeComment || 'APPROVED';
                    errorTextLicenseeComments.value = 'Please provide Comments to the Licensee.';
                } else {
                    if (submissionForm.licenseeComment) submissionForm.licenseeComment = submissionForm.licenseeComment.replaceAll('APPROVED', '');
                }
                if ([RESUBMIT_WITH_CHANGES_STATUS, REJECT_STATUS].includes(actionName)) {
                    errorTextLicenseeComments.value = `Please enter the reason for ${actionName} in Licensee Comments.`;
                    if (submissionForm.licenseeComment === '') {
                        if (notifiersEnabled.value) notificationsStack.value.push({
                            type: WARNING,
                            message: 'Please make sure you have added Licensee Comments for this action.'
                        });
                    }
                }
            } else if ([SOCIAL_REVIEW_STEP, GAMES_REVIEW_STEP, DIGITAL_REVIEW_STEP].includes(submissionForm.currentStepName)) {
                if ([APPROVE_STATUS].includes(actionName)) {
                    submissionForm.licenseeComment = submissionForm.licenseeComment || 'APPROVED';
                    errorTextLicenseeComments.value = 'Please provide Comments to the Licensee.';
                } else {
                    if (submissionForm.licenseeComment) submissionForm.licenseeComment = submissionForm.licenseeComment.replaceAll('APPROVED', '');
                }
                if ([APPROVE_WITH_CHANGES_STATUS, RESUBMIT_WITH_CHANGES_STATUS, REJECT_STATUS].includes(actionName)) {
                    errorTextLicenseeComments.value = `Please enter the reason for ${actionName} in Licensee Comments.`;
                    if (submissionForm.licenseeComment === '') {
                        if (notifiersEnabled.value) notificationsStack.value.push({
                            type: WARNING,
                            message: 'Please make sure you have added Licensee Comments for this action.'
                        });
                    }
                }
            } else if (submissionForm.currentStepName === CREATIVE_REVIEW_STEP) {
                if ([APPROVE_STATUS].includes(actionName)) {
                    submissionForm.licenseeComment = submissionForm.licenseeComment || 'APPROVED';
                    errorTextLicenseeComments.value = 'Please provide Comments to the Licensee.';
                } else {
                    if (submissionForm.licenseeComment) submissionForm.licenseeComment = submissionForm.licenseeComment.replaceAll('APPROVED', '');
                }
                if ([APPROVE_WITH_CHANGES_STATUS, RESUBMIT_WITH_CHANGES_STATUS, REJECT_STATUS].includes(actionName)) {
                    if (submissionForm.submissionTypeName === PRESS_RELEASE_SUBMISSION_TYPE) {
                        errorTextLicenseeComments.value = actionName === REJECT_STATUS ? `Please enter the reason for ${actionName} in Licensee Comments.` : 'Please make sure you have added File Name in Licensee Comment.';
                        if (submissionForm.licenseeComment === '') {
                            if (notifiersEnabled.value) notificationsStack.value.push({
                                type: WARNING,
                                message: actionName === REJECT_STATUS ? 'Please make sure you have added Licensee Comments for this action.' : 'Please make sure you have added File Name in Licensee Comment.'
                            });
                        }
                    } else {
                        errorTextLicenseeComments.value = `Please enter the reason for ${actionName} in Comments.`;
                        if (submissionForm.licenseeComment === '') {
                            if (notifiersEnabled.value) notificationsStack.value.push({
                                type: WARNING,
                                message: 'Please make sure you have added Licensee Comments for this action.'
                            });
                        }
                    }
                }
            } else if (submissionForm.currentStepName === BAC_SUPERVISOR_STEP) {
                if ([APPROVE_WITH_CHANGES_STATUS, RESUBMIT_WITH_CHANGES_STATUS, REJECT_STATUS].includes(actionName)) {
                    if (submissionForm.submissionTypeName === PRESS_RELEASE_SUBMISSION_TYPE) {
                        errorTextLicenseeComments.value = actionName === REJECT_STATUS ? `Please enter the reason for ${actionName} in Comments.` : 'Please enter the File Name in Licensee Comments.';
                        if (submissionForm.licenseeComment === '') {
                            if (notifiersEnabled.value) notificationsStack.value.push({
                                type: WARNING,
                                message: actionName === REJECT_STATUS ? 'Please make sure you have added Licensee Comments for this action.' : 'Please make sure you have added File Name in Licensee Comment.'
                            });
                        }
                    } else {
                        errorTextLicenseeComments.value = `Please enter the reason for ${actionName} in Comments.`;
                        if (submissionForm.licenseeComment === '') {
                            if (notifiersEnabled.value) notificationsStack.value.push({
                                type: WARNING,
                                message: 'Please make sure you have added Licensee Comments for this action.'
                            });
                        }
                    }
                }
            } else if (submissionForm.currentStepName === LICENSEE_STEP || submissionFormMode.value === 'NEW') {
                if (actionName === '') {
                    errorTextLicenseeComments.value = 'Please enter Comments for your submission.';
                }
            }
        };

        watch(
            () => submissionForm.assignUser,
            () => {
                licenseeCommentFieldState();
                validationCheckForCommentFields();
            }
        );

        const licenseeCommentFieldState = () => {
            if (['DRAFT', 'NEW'].includes(submissionFormMode.value) && (!submissionForm.licenseeComment || !submissionForm.licenseeComment.length)) {
                return REQUIRED;
            }

            // adding checks if submission is not new
            if (submissionFormMode.value !== 'NEW') {
                if (submissionForm.submissionStatus !== 'Inflight') return EDITABLE;
                if (submissionForm.assignUser !== userId.value) return EDITABLE;
            }

            if (submissionForm.currentStepName === BAC_REVIEW_STEP) {
                if ([RESUBMIT_WITH_CHANGES_STATUS, REJECT_STATUS].includes(submissionForm.currentActionName)) {
                    return REQUIRED;
                } else {
                    return EDITABLE;
                }
            } else if ([SOCIAL_REVIEW_STEP, DIGITAL_REVIEW_STEP, GAMES_REVIEW_STEP].includes(submissionForm.currentStepName)) {
                if ([APPROVE_STATUS, APPROVE_WITH_CHANGES_STATUS, RESUBMIT_WITH_CHANGES_STATUS, REJECT_STATUS].includes(submissionForm.currentActionName)) {
                    return REQUIRED;
                }
            } else if (submissionForm.currentStepName === SAFETY_STEP) {
                if ([APPROVE_STATUS, RESUBMIT_WITH_CHANGES_STATUS, REJECT_STATUS].includes(submissionForm.currentActionName)) {
                    return REQUIRED;
                }
            } else if (submissionForm.currentStepName === LEGAL_REVIEW_STEP) {
                if ([APPROVE_STATUS, APPROVE_WITH_CHANGES_STATUS, RESUBMIT_WITH_CHANGES_STATUS, REJECT_STATUS].includes(submissionForm.legalStatusName)) {
                    return REQUIRED;
                }
            } else if (submissionForm.currentStepName === CREATIVE_REVIEW_STEP) {
                if ([APPROVE_STATUS, APPROVE_WITH_CHANGES_STATUS, RESUBMIT_WITH_CHANGES_STATUS, REJECT_STATUS].includes(submissionForm.creativeStatusName)) {
                    return REQUIRED;
                }
            } else if (submissionForm.currentStepName === BAC_SUPERVISOR_STEP) {
                if ([APPROVE_WITH_CHANGES_STATUS, RESUBMIT_WITH_CHANGES_STATUS, REJECT_STATUS].includes(submissionForm.currentActionName)) {
                    return REQUIRED;
                }
            } else if (submissionForm.currentStepName === LICENSEE_STEP || submissionFormMode.value === 'NEW') {
                return REQUIRED;
            }
            return EDITABLE;
        };

        // INTERNAL NOTES AREA
        const dynamicInternalNotesTextPopulation = (actionName) => {
            if (submissionForm.currentStepName === BAC_REVIEW_STEP) {
                if ([APPROVE_STATUS, ROUTE_BACK_STATUS].includes(actionName)) {
                    submissionForm.internalNote = actionName === APPROVE_STATUS ? submissionForm.internalNote || 'APPROVED' : '';
                    errorTextInternalNotes.value = 'Please provide Internal Comments.';
                } else {
                    if (submissionForm.internalNote) submissionForm.internalNote = submissionForm.internalNote.replaceAll('APPROVED', '');
                }
            } else if (submissionForm.currentStepName === LEGAL_REVIEW_STEP) {
                if (submissionForm.escalateToSupervisor === 'Y' && submissionForm.internalNote === '') {
                    errorTextInternalNotes.value = 'Please enter the reason for Escalate to Supervisor in Internal Notes.';
                    submissionFormValidations.internalNote = REQUIRED;
                }

                if ([ASSIGN_TO_STATUS, ROUTE_BACK_STATUS].includes(actionName)) {
                    errorTextInternalNotes.value = `Please enter the reason for ${actionName === ROUTE_BACK_STATUS ? actionName : 'Assigning Task'} in Internal Notes.`;
                    if (notifiersEnabled.value) {
                        notificationsStack.value.push({
                            type: WARNING,
                            message: 'Please enter the reason for Assigning the Task in Internal Notes.'
                        });
                    }
                }
            } else if (submissionForm.currentStepName === CREATIVE_REVIEW_STEP) {
                if ([ASSIGN_TO_STATUS, ROUTE_BACK_STATUS].includes(actionName)) {
                    errorTextInternalNotes.value = `Please enter the reason for ${actionName === ROUTE_BACK_STATUS ? actionName : 'Assigning Task'} in Internal Notes.`;
                    if (notifiersEnabled.value) {
                        notificationsStack.value.push({
                            type: actionName === ROUTE_BACK_STATUS ? ERROR : WARNING,
                            message: `Please enter the reason for ${actionName === ROUTE_BACK_STATUS ? actionName : 'Assigning Task'} in Internal Notes.`
                        });
                    }
                }
            } else if (submissionForm.currentStepName === SOCIAL_REVIEW_STEP) {
                if ([ASSIGN_TO_STATUS].includes(actionName)) {
                    errorTextInternalNotes.value = 'Please enter the reason for Assigning Task in Internal Notes.';
                    if (notifiersEnabled.value) {
                        notificationsStack.value.push({
                            type: WARNING,
                            message: 'Please enter the reason for Assigning Task in Internal Notes.'
                        });
                    }
                }
            } else if ([BAC_SUPERVISOR_STEP, BAC_FINAL_STEP, GAMES_REVIEW_STEP, SOCIAL_REVIEW_STEP].includes(submissionForm.currentStepName)) {
                if ([ROUTE_BACK_STATUS].includes(actionName)) {
                    errorTextInternalNotes.value = `Please enter the reason for ${actionName} in Internal Notes.`;
                    if (notifiersEnabled.value)  {
                        notificationsStack.value.push({
                            type: ERROR,
                            message: `Please enter the reason for ${actionName} in Internal Notes.`
                        });
                    }
                }
                if ([APPROVE_STATUS].includes(actionName)) {
                    submissionForm.internalNote = submissionForm.internalNote || 'APPROVED';
                    errorTextInternalNotes.value = 'Please provide Internal Comments.';
                } else {
                    if (submissionForm.internalNote) submissionForm.internalNote = submissionForm.internalNote.replaceAll('APPROVED', '');
                }
            }
        };

        const reviewerRoles = [
            BA_ROLES.BAC_REVIEWER_ROLE,
            BA_ROLES.LEGAL_ROLE,
            BA_ROLES.CREATIVE_ROLE,
            BA_ROLES.SAFETY_ROLE,
            BA_ROLES.BAC_ADMIN_ROLE,
            BA_ROLES.BAC_FINAL_ROLE,
            BA_ROLES.BAC_SUPERVISOR_ROLE,
            BA_ROLES.SOCIAL_REVIEWER_ROLE,
            BA_ROLES.BA_ADMIN_ROLE,
            BA_ROLES.SKU_ADMIN_ROLE
        ];

        const internalNotesFullAreadFieldState = computed(() => {
            const tmpRoles = (userRoles.value || []).filter(r => reviewerRoles.includes(r));
            const proxyRoles = ((proxyUser.value && proxyUser.value?.roles) || []).filter(r => reviewerRoles.includes(r));
            if (tmpRoles.length === 0 || (proxyUser.value && proxyRoles.length === 0)) {
                return INVISIBLE;
            }
            return EDITABLE;
        });

        const internalNoteFieldState = computed(() => {
            const tmpRoles = (userRoles.value || []).filter(r => reviewerRoles.includes(r));
            if (tmpRoles.length === 0) {
                return INVISIBLE;
            } else if (submissionForm.currentStepName === BAC_REVIEW_STEP) {
                if ([APPROVE_STATUS, ROUTE_BACK_STATUS].includes(submissionForm.currentActionName)) {
                    return REQUIRED;
                }
            } else if (submissionForm.currentStepName === LEGAL_REVIEW_STEP) {
                if (submissionForm.escalateToSupervisor === 'Y' && submissionForm.internalNote === '') return REQUIRED;
                if ([ASSIGN_TO_STATUS, ROUTE_BACK_STATUS].includes(submissionForm.legalStatusName)) {
                    return REQUIRED;
                }
            } else if (submissionForm.currentStepName === CREATIVE_REVIEW_STEP) {
                if ([ASSIGN_TO_STATUS, ROUTE_BACK_STATUS].includes(submissionForm.creativeStatusName)) {
                    return REQUIRED;
                }
            } else if (submissionForm.currentStepName === SOCIAL_REVIEW_STEP) {
                if ([ASSIGN_TO_STATUS].includes(submissionForm.currentActionName)) {
                    return REQUIRED;
                }
            } else if (submissionForm.currentStepName === BAC_SUPERVISOR_STEP || submissionForm.currentStepName === BAC_FINAL_STEP) {
                if ([APPROVE_STATUS, ROUTE_BACK_STATUS].includes(submissionForm.currentActionName)) {
                    return REQUIRED;
                }
            }
            return EDITABLE;
        });

        const previousInternalNoteFieldState = computed(() => {
            const tmpRoles = (userRoles.value || []).filter(r => reviewerRoles.includes(r));
            if (tmpRoles.length === 0) {
                return INVISIBLE;
            } else if ([BA_ADMIN_ROLE].some(role => userRoles.value.includes(role))) {
                return VIEW_ONLY;
            } else {
                return tmpRoles === 0 && LICENSEE_STEP === submissionForm?.currentStepName ? INVISIBLE : EDITABLE;
            }
        });

        const internalNotesHistoryButtonState = computed(() => {
            const tmpRoles = (userRoles.value || []).filter(r => reviewerRoles.includes(r));
            if (tmpRoles.length === 0) {
                return INVISIBLE;
            } else if ([BA_ADMIN_ROLE].some(role => userRoles.value.includes(role))) {
                return EDITABLE;
            } else {
                return tmpRoles === 0 && LICENSEE_STEP === submissionForm?.currentStepName ? INVISIBLE : EDITABLE;
            }
        });

        watch(
            () => [submissionForm.licenseeComment, submissionForm.internalNote],
            () => {
                submissionFormValidations.licenseeComment = (licenseeCommentFieldState() === REQUIRED && !submissionForm.licenseeComment) || submissionForm.licenseeComment.length > 4000 ? REQUIRED_FIELD : '';
                submissionFormValidations.internalNote = (internalNoteFieldState.value === REQUIRED && !submissionForm.internalNote) || submissionForm.internalNote.length > 4000 ? REQUIRED_FIELD : '';
            },
            {
                deep: true,
                immediate: true
            }
        );

        // custom Lines logic
        // if we change it to array as we are using multi select now. will break for existing submissions.
        const updateCustomLineValueInComments = (newValue) => {
            const customLine = (fetchedSubmissionsCustomLinesList.value.find(lines => newValue === lines.displayText));
            if (!submissionForm.licenseeComment.includes(customLine.line)) {
                submissionForm.licenseeComment += submissionForm.licenseeComment ? `\n${customLine.line}` : customLine.line;
            }
        };

        const fetchedSubmissionsCustomLinesList = computed(() => store.getters['baSubmissions/getCustomLines']);
        const customLinesOptions = computed(() => {
            let newComments;
            if (fetchedSubmissionsCustomLinesList.value) {
                newComments = fetchedSubmissionsCustomLinesList.value.filter(comment => {
                    if (submissionForm.currentStepName === LEGAL_REVIEW_STEP && comment.userRoles.includes('LEGAL')) {
                        return comment;
                    } else if (submissionForm.currentStepName === CREATIVE_REVIEW_STEP && comment.userRoles.includes('CREATIVE')) {
                        return comment;
                    } else if ([BAC_REVIEW_STEP, BAC_SUPERVISOR_STEP].includes(submissionForm.currentStepName) && comment.userRoles.includes('BAC')) {
                        return comment;
                    }
                });
            }

            return newComments?.length > 0 ? newComments.map(line => line.displayText) : [];
        });
        const customLinesLoading = ref(false);
        const fetchCustomLines = async () => {
            try {
                if (customLinesOptions.value.length > 0 || customLinesLoading.value) {
                    return;
                }
                customLinesLoading.value = true;
                await store.dispatch('baSubmissions/fetchCustomLinesSubmission');
            } catch (err) {
                console.error(err);
            } finally {
                customLinesLoading.value = false;
            }
        };

        // comments section logic
        const submissionsCommentsList = computed(() => store.getters['baSubmissions/getSubmissionComments'] || []);

        const commentsParams = reactive({
            submissionId: submissionForm.submissionId,
            refObjTypeKey: ''
        });

        watch(
            () => submissionForm.submissionId,
            (__new, __old) => {
                commentsParams.submissionId = __new;
            }
        );

        const doesUserHaveAssociatedLicensees = computed(() => store.getters['auth/doesUserHaveAssociatedLicensees']);

        const fetchSubmissionCommentsHistory = async () => {
            try {
                isCommentsListLoading.value = true;

                const params = { ...commentsParams };
                if (
                    doesUserHaveAssociatedLicensees.value && submissionForm.submissionStatus !== 'Draft' &&
                  submissionForm.currentStepName !== LICENSEE_STEP
                ) params.licensee = 'authorized';

                await store.dispatch('baSubmissions/loadSubmissionComments', { params });

                let __comments = submissionsCommentsList.value;


                if (__comments && commentsParams.refObjTypeKey === 2 && submissionForm.currentStepName === LICENSEE_STEP) {
                    __comments = __comments.filter(c =>
                        [LEGAL_REVIEW_STEP, CREATIVE_REVIEW_STEP, SAFETY_STEP, SOCIAL_REVIEW_STEP, GAMES_REVIEW_STEP, DIGITAL_REVIEW_STEP, LICENSEE_STEP].includes(c.submissionStepKeyName) ||
                        ['Reject', 'Resubmit with changes'].includes(c.submissionStatusKeyName)
                    );
                }

                if (
                    __comments && commentsParams.refObjTypeKey === 2
                ) {
                    try {
                        const invertedArray = [...__comments].sort((a, b) => b.noteKey - a.noteKey); // Descending Sort
                        const idx = invertedArray.findIndex(c => c.submissionStepKeyName === 'Licensee');
                        if (idx) for (let i = 0; i < idx; i++) invertedArray[i].isDeletable = true;
                        __comments = invertedArray.sort((a, b) => a.noteKey - b.noteKey); // Ascending Sort
                    } catch (e) {}
                }
                __comments.forEach(comment => {
                    if (comment.submissionStepKeyName === SOCIAL_REVIEW_STEP && submissionForm.materialsSupplied === SOCIAL_DIGITAL_VIDEO_REVIEW ) {
                        comment.reviewName = SOCIAL_DIGITAL_VIDEO_REVIEW;
                    } else if (comment.submissionStepKeyName === LEGAL_REVIEW_STEP) {
                        if (userRoles.value.length <= 2 && ([BA_RO_ROLE, LICENSEE_ROLE].includes(userRoles.value[0]) || [BA_RO_ROLE, LICENSEE_ROLE].includes(userRoles.value[1]))) {
                            comment.reviewName = CONTRACT_COORDINATOR;
                        }
                    } else {
                        comment.reviewName = '';
                    }
                });
                setCommentsLists(__comments.sort((a, b) => a.noteKey - b.noteKey));
            } catch (err) {
                console.error(err);
            } finally {
                isCommentsListLoading.value = false;
            }
        };

        const openInternalNotesHistory = () => {
            commentsParams.refObjTypeKey = 1;
            fetchSubmissionCommentsHistory();
            commentsModalTitle.value = 'Internal Notes History';
            setCommentsModalVisibility(true);
        };

        const openLicenseeCommentsHistory = () => {
            commentsParams.refObjTypeKey = 2;
            fetchSubmissionCommentsHistory();
            commentsModalTitle.value = 'Licensee Comments History';
            setCommentsModalVisibility(true);
        };

        onMounted(() => {
            if (submissionFormMode.value === 'NEW') {
                submissionForm.comments = [];
                submissionForm.licenseeComment = '';
                submissionForm.internalNote = '';
            }
        });

        const filterCommentsByRefObjTypeKey = refObjTypeKey => submissionForm.comments.filter(c => c.refObjTypeKey === refObjTypeKey);

        // const internalNotes = computed(() => submissionForm.comments?.length > 0 ? filterCommentsByRefObjTypeKey(1) : []);
        // const licenseeComments = computed(() => submissionForm.comments?.length > 0 ? filterCommentsByRefObjTypeKey(2) : []);

        const previousInternalNote = computed(() => {
            if (!submissionForm.comments || !submissionForm.comments.length) return '';
            const __comments = filterCommentsByRefObjTypeKey(1);
            return __comments.length > 0 ? __comments[__comments.length - 1].description : '';
        });

        const previousLicenseeComment = computed(() => {
            if (!submissionForm.comments || !submissionForm.comments.length) return '';
            const __comments = filterCommentsByRefObjTypeKey(2);
            return __comments.length > 0 ? __comments[__comments.length - 1].description : '';
        });

        // comments modal logic
        const showCommentsModal = ref(false);
        const setCommentsModalVisibility = (modalVisibility) => {
            showCommentsModal.value = modalVisibility;
        };
        const commentsModalTitle = ref('');

        return {
            submissionForm,
            // comments section validations
            INVISIBLE,
            REQUIRED,
            submissionFormValidations,
            customCommentsDropdownState,
            licenseeCommentFieldState,
            internalNoteFieldState,
            internalNotesFullAreadFieldState,
            previousInternalNoteFieldState,
            internalNotesHistoryButtonState,
            getDistinctArray,
            fetchCustomLines,
            customLinesOptions,
            // comments section
            openLicenseeCommentsHistory,
            openInternalNotesHistory,
            previousLicenseeComment,
            previousInternalNote,
            // comments modal logic
            showCommentsModal,
            setCommentsModalVisibility,
            commentsModalTitle,
            errorTextInternalNotes,
            errorTextLicenseeComments,
            // isSuperAdmin,
            updateCustomLineValueInComments
        };
    }
};
</script>
